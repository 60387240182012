import request from './request'

const api = {
	// 获取角色列表
	rolelist: '/SystemRole/index',
	// 树形结构菜单（角色权限）
	treeMenu: '/SystemMenus/tree',
	// 添加角色
	add: '/SystemRole/create',
	// 编辑角色
	edit: '/SystemRole/update',
	// 获取角色信息
	info: '/SystemRole/read',
	// 删除角色
	dele: '/SystemRole/delete',

}

export {
	request,
	api
}
